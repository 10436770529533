/* eslint-disable no-unused-vars */
interface IEnvironmentConstants {
  IFRAME_URL: string;
}

let EnvironmentConstants: IEnvironmentConstants;
// @ts-ignore
if (ENV_IS_PRODUCTION) {
  EnvironmentConstants = {
    IFRAME_URL: "https://widget.dromo.io/",
  };
} else {
  EnvironmentConstants = {
    IFRAME_URL: "http://localhost:3000/",
  };
}

/** @internal */
export const IFRAME_URL = EnvironmentConstants.IFRAME_URL;

/** @internal */
export const V1_IFRAME_URL = "https://widget-v1.dromo.io/";

/** @internal */
export enum EventTypes {
  INIT_IFRAME = "INIT_IFRAME",
}
